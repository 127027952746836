import { fetchGet, fetchPost } from './index';

//获取搜索条件下拉框数据
export const getSearchList = (params = {}): Promise<any> => {
  return fetchGet('/rvapi/ar/search', { params });
};
//获取表格数据
export const getTableList = (params = {}): Promise<any> => {
  return fetchGet('/rvapi/ar/list', { params });
};
//行文件下载
export const download = (params = {}): Promise<any> => {
  return fetchGet('/rvapi/ar/download', { params });
};
